// Font-Family
$font1: "FuturaPT", sans-serif;
// General
$MainFontSize: 16px;
$mo-ease: background 0.3s ease,
    border-color 0.3s ease,
    border-style 0.3s ease,
    border-width 0.3s ease,
    color 0.3s ease,
    opacity 0.3s ease,
    text-decoration-color 0.3s ease,
    text-decoration-line 0.3s ease,
    text-decoration-dtyle 0.3s ease,
    text-decoration-thickness 0.3s ease,
    text-underline-offset 0.3s ease,
    transform 0.3s ease;
// Colors
body {
    --mo-color-mo-color: #8ea1ff;
}
$sitegray1: #141418;
$sitegray3: #898f99;
$sitegray4: #2b2c31;
