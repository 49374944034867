.mo-player-site {
    display: none;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 50000;
    &.bottom,
    &.full {
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(0, 0, 0, 0.7);
        bottom: 0;
        display: flex;
        height: 55px;
        .mo-player-site-block {
            background: black;
            height: 55px;
            position: relative;
            width: 55px;
            z-index: 1;
            &:after {
                bottom: 0;
                content: "";
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            .mo-player-site-cover {
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
            &.full {
                bottom: 55px;
                height: 300px;
                position: absolute;
                right: 0;
                width: 500px;
                &:after {
                    display: none;
                }
                &.is-mobile {
                    width: 100%;
                }
                &.platform-9 {
                    height: 120px;
                }
                &.platform-8 {
                    height: 400px;
                }
            }
        }
        .mo-player-site-platform-wrap {
            align-items: center;
            display: flex;
            color: white;
            padding: 10px;
            justify-content: center;
            .mo-player-site-powered {
                flex-shrink: 0;
                font-size: 14px;
            }
            .mo-player-site-platform {
                margin-left: 5px;
                height: 15px;
            }
        }
        .mo-player-site-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px 10px;
            flex: 1;
            &.is-mobile {
                display: none;
            }
            .mo-player-site-info-name {
                color: white;
                font-size: 14px;
                font-weight: 600;
            }
        }
        .mo-player-site-range {
            display: block;
        }
        .mo-player-site-right {
            align-items: center;
            display: flex;
            padding-right: 10px;
            &.is-mobile {
                display: none;
            }
            .mo-player-site-range-duration {
                padding: 0 15px;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: $sitegray3;
                justify-content: space-between;
            }
            .mo-player-site-block-header {
                .mo-new-icon-chevron-up-solid {
                    &.rotate {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        .mo-player-site-action {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            left: 50%;
            padding: 5px;
            position: absolute;
            transform: translate(-50%);
            .mo-player-site-action-icon {
                display: flex;
                font-size: 20px;
                margin-left: 20px;
            }
            .mo-player-site-action-full {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                .mo-player-site-action-icon-full {
                    align-items: center;
                    display: flex;
                    padding: 0 10px;
                    &.mo-player-site-action-icon-full-main {
                        font-size: 40px;
                        height: 40px;
                    }
                    &.is-mobile {
                        &.album {
                            display: none;
                        }
                    }
                }
            }
        }
        .mo-player-minimize {
            display: none;
            height: 100%;
            left: 7px;
            position: absolute;
            top: 0;
            &.is-mobile {
                display: flex;
            }
        }
        .mo-player-close {
            display: none;
            height: 100%;
            position: absolute;
            right: 7px;
            top: 0;
            &.is-mobile {
                display: flex;
            }
        }
    }
}
