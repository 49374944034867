.form-success-toast-site {
    &::part(button) {
        font-size: 14px;
        height: auto;
        margin: 10px;
    }
    &::part(container) {
        background: #212121;
        border-radius: 14px;
        border: 1px solid white;
    }
    &::part(message) {
        color: white;
    }
}
