.template-block-823 {
    .t-823-table {
        border-spacing: 0;
        position: relative;
        thead {
            .t-823-thead-tr {
                // border-bottom: 1px solid rgb(212, 85, 0);
                // position: sticky;
                top: 0;
                z-index: 2;
                .t-823-thead-th {
                    border-bottom: 3px solid #e5e5e5;
                    border-left: 1px solid #e5e5e5;
                    border-right: 1px solid #e5e5e5;
                    border-top: 2px solid #e5e5e5;
                    // &:first-child {
                    //     border-left: 3px solid #e5e5e5;
                    // }
                    // &:last-child {
                    //     border-right: 3px solid #e5e5e5;
                    // }
                    .t-823-thead-th-div-wrap {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        .t-823-thead-th-div {
                            font-size: 13px;
                            padding: 5px 7px;
                            text-align: left;
                            white-space: pre-line;
                            word-break: normal;
                        }
                    }
                }
            }
        }
        tbody {
            tr:nth-child(even) {
                background-color: #dddddd;
                td {
                    background-color: #dddddd;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                    .table-column,
                    .tr-action {
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }
            }
            tr:nth-child(odd) {
                background-color: white;
                td {
                    background-color: white;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                    .table-column,
                    .tr-action {
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }
            }
            td {
                border-bottom: 1px solid #e5e5e5;
                border-left: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
                &:first-child {
                    border-left: 3px solid #e5e5e5;
                }
                &:last-child {
                    border-right: 3px solid #e5e5e5;
                }
                &.tr-action {
                    transition: background-color 0.3s ease;
                    width: 80px;
                    .tr-action-div {
                        display: flex;
                        justify-content: center;
                        .main-icon-block-web {
                            display: flex;
                            .mo-new-icon-pencil-alt-solid {
                                font-size: 14px;
                            }
                            .mo-new-icon-link-solid {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .t-823-tbody-tr {
                .t-823-actions {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 5px 7px;
                }
                .t-823-value {
                    font-size: 13px;
                    padding: 5px 7px;
                    white-space: pre-line;
                    word-break: normal;
                    a {
                        text-decoration: underline;
                    }
                    &.boolean {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        &.mo-new-icon-check-circle-solid {
                            color: green;
                        }
                        &.mo-new-icon-times-solid {
                            color: #dc3545;
                        }
                    }
                    .phonenumber {
                        background: transparent;
                        border: none;
                        padding: 0;
                        .PhoneInputCountrySelectArrow {
                            display: none;
                        }
                        .PhoneInputInput {
                            background: transparent;
                            border: none;
                            padding: 0;
                        }
                        .PhoneInputCountry {
                            border: 0;
                        }
                        .PhoneInputCountrySelect {
                            display: none;
                        }
                        .PhoneInputCountryIcon {
                            background: none;
                            box-shadow: none;
                            height: 13px;
                        }
                    }
                }
            }
        }
    }
}
