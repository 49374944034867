.mo-dialog-class-site {
    background: rgba(24, 24, 24, 0.1);
    .mo-dialog-wrapper {
        background: #202020;
        color: #ffffff;
        border-radius: 0;
        outline: 1px solid #383838;
        &.fix-height {
            height: 600px;
        }
        &.min-height {
            min-height: 600px;
        }
        &.fix-width {
            width: 600px;
        }
        &.full-width {
            width: 100%;
        }
        .mo-dialog-header {
            background: #181818;
            border-bottom: 1px solid #383838;
            font-size: 16px;
            padding: 10px;
            position: relative;
            text-align: center;
            .mo-dialog-header-close-wrap {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: centers;
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 10px;
                .mo-dialog-header-close {
                    background: transparent;
                    color: white;
                    .main-icon {
                        font-size: 25px;
                    }
                }
            }
        }
        .mo-dialog-content {
            background: #202020;
            padding: 15px !important;
            position: relative;
            &.no-padding {
                padding: 0px !important;
            }
            .pre-line {
                white-space: pre-line;
            }
            .mo-dialog-searchbar-wrap {
                background: #181818;
                border-bottom: 1px solid #383838;
                padding: 10px;
                position: sticky;
                top: 0;
                z-index: 1;
                .mo-dialog-select {
                    padding-bottom: 10px;
                }
            }
        }
        .mo-dialog-footer {
            align-items: center;
            background: #181818;
            border-top: 1px solid #383838;
            display: flex;
            padding: 10px;
            .button-web,
            .button-wrap-web {
                width: 100%;
            }
        }
        .mo-dialog-header-close-wrap-form-modal {
            align-items: flex-start;
            bottom: 0;
            display: flex;
            justify-content: centers;
            padding: 10px;
            position: absolute;
            right: 0;
            top: 0;
            .mo-dialog-header-close {
                background: transparent;
                color: black;
                .main-icon {
                    font-size: 25px;
                }
            }
        }
    }
    &.inline-form {
        .mo-dialog-wrapper {
            height: 95vh;
            .mo-dialog-content {
                display: flex;
                .mo-dialog-content-left {
                    border-right: 1px solid #383838;
                    display: flex;
                    flex-direction: column;
                    width: 370px;
                    .mo-dialog-content-form-children {
                        flex: 1;
                        padding: 15px;
                    }
                }
                .mo-dialog-content-right {
                    flex: 1;
                    position: relative;
                    .mo-dialog-content-table-container {
                        table {
                            border-spacing: 0;
                            min-width: 100%;
                            position: relative;
                            thead {
                                tr {
                                    th {
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr:nth-child(even) {
                                    background-color: #181818;
                                    td {
                                        background-color: #181818;
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                                tr:nth-child(odd) {
                                    background-color: #202020;
                                    td {
                                        background-color: #202020;
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.half {
            .mo-dialog-content {
                .mo-dialog-content-left {
                    width: 50%;
                }
            }
        }
    }
}
.pre-line {
    white-space: pre-line;
}
