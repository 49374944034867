.template-block-780 {
    display: flex;
    max-width: 100%;
    position: relative;
    /* Hide scrollbar IE 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    -webkit-overflow-scrolling: touch;
    /* Hide scrollbar Firefox */
    scrollbar-width: thin !important;
    &.lg-19 {
        direction: rtl;
    }
    &.is-windows {
        &::-webkit-scrollbar {
            background: #eae9e9;
            /* Hide scrollbar Safari and Chrome */
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a0a0a0;
        }
    }
    &.animation {
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        max-width: none;
        width: auto;
        @keyframes infiniteLeftToRight {
            0% {
                transform: translateX(-50%);
            }
            100% {
                transform: translateX(0);
            }
        }
        @keyframes infiniteRightToLeft {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-50%);
            }
        }
    }
    &.is-parent-hovered {
        &:after {
            background: var(--mo-color-mo-color);
            content: "";
            height: 100%;
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 50;
        }
    }
    &.transition {
        transition: background 0.3s ease;
    }
}
