.template-block-816 {
    max-width: 100%;
    .t-816-block {
        border-radius: 6px;
        box-shadow: 0 0.0625rem 0.1875rem hsla(239, 34%, 27%, 0.15);
        overflow: hidden;
        .t-816-navsteps {
            .t-816-navstep {
                background-color: #3e64ad;
                color: white;
                padding: 20px 10px;
                width: 25%;
                border-right: 1px solid white;
                &.current {
                    pointer-events: none;
                }
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &:last-child {
                    border-right: none;
                }
                .t-816-navtext {
                    padding-left: 10px;
                }
            }
        }
        .t-816-step1 {
            .t-816-variants {
                padding: 10px 20px;
                .t-816-variant {
                    align-items: center;
                    display: flex;
                    border-bottom: 1px solid #e6e6e6;
                    padding: 10px 0;
                    &:last-child {
                        border: none;
                    }
                    .t-816-variant-name {
                        flex: 1;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .t-816-variant-price-wrap {
                        align-items: center;
                        display: flex;
                        .t-816-variant-price {
                            font-size: 26px;
                            font-weight: 600;
                        }
                        .t-816-input-wrap {
                            padding-left: 20px;
                            input {
                                background: transparent;
                                border: 1px solid #e6e6e6;
                                max-width: 100%;
                                outline: none;
                                padding: 10px;
                                text-align: center;
                                width: 100px;
                            }
                        }
                    }
                }
            }
            .t-816-total {
                align-items: center;
                background-color: #3e64ad;
                color: white;
                display: flex;
                font-size: 20px;
                font-weight: 400;
                justify-content: flex-end;
                padding: 10px 20px;
                .t-816-total-amount {
                    font-size: 26px;
                    font-weight: 700;
                    min-width: 100px;
                    text-align: right;
                }
            }
        }
        .t-816-step2 {
            padding: 20px;
            .t-816-variant-wrap {
                padding-bottom: 10px;
                .t-816-variant-name {
                    font-size: 20px;
                    font-weight: 600;
                }
                .t-816-participant {
                    padding: 10px 0;
                    .t-816-participant-wrap {
                        background-color: rgb(247, 249, 253);
                        border-radius: 6px;
                        padding: 20px;
                        .t-816-participant-title {
                            display: flex;
                            align-items: center;
                            padding-bottom: 10px;
                            .t-816-icon {
                                display: flex;
                                align-items: center;
                                margin-right: 10px;
                            }
                        }
                        .t-816-participant-inputs {
                            background: white;
                            border-radius: 6px;
                            display: flex;
                            flex-wrap: wrap;
                            padding: 20px;
                            .t-816-participant-input-wrap {
                                width: 50%;
                                padding: 5px;
                                &.full {
                                    width: 100%;
                                }
                                .t-816-participant-label {
                                    display: block;
                                }
                                .t-816-participant-input {
                                    background: transparent;
                                    border: 1px solid #e6e6e6;
                                    outline: none;
                                    padding: 10px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .t-816-step3 {
            padding: 20px;
            .t-816-contact-info-title {
                font-size: 20px;
                font-weight: 600;
            }
            .t-816-contact-info-inputs {
                border-radius: 6px;
                padding: 20px 0;
                display: flex;
                .t-816-contact-info-input-wrap {
                    width: 50%;
                    &:first-child {
                        padding-right: 10px;
                    }
                    &:last-child {
                        padding-left: 10px;
                    }
                    .t-816-contact-info-label {
                        display: block;
                    }
                    .t-816-contact-info-input {
                        background: transparent;
                        border: 1px solid #e6e6e6;
                        outline: none;
                        padding: 10px;
                        width: 100%;
                    }
                }
            }
            .t-816-contact-info-email-input-wrap {
                width: 100%;
                .t-816-contact-info-label {
                    display: block;
                }
                .t-816-contact-info-input {
                    background: transparent;
                    border: 1px solid #e6e6e6;
                    outline: none;
                    padding: 10px;
                    width: 100%;
                }
            }
        }
        .t-816-step4 {
            .t-816-review {
                padding: 20px;
                .t-816-review-title {
                    background-color: rgb(247, 249, 253);
                    border-radius: 6px;
                    display: block;
                    font-size: 26px;
                    font-weight: 600;
                    padding: 10px;
                    text-align: center;
                }
                .t-816-review-sub-title {
                    display: block;
                    font-size: 20px;
                    font-weight: 700;
                    padding: 15px 0;
                }
                .t-816-review-ticket-wrap {
                    padding-bottom: 15px;
                    .t-816-review-ticket-participant {
                        display: block;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .t-816-review-ticket-variant {
                        display: flex;
                        .t-816-review-ticket-variant-name {
                            display: block;
                            font-size: 16px;
                            flex: 1;
                        }
                        .t-816-review-ticket-variant-price {
                            display: block;
                            font-size: 16px;
                        }
                    }
                }
            }
            .t-816-total {
                align-items: center;
                background-color: #3e64ad;
                color: white;
                display: flex;
                font-size: 20px;
                font-weight: 400;
                justify-content: flex-end;
                padding: 10px 20px;
                .t-816-total-amount {
                    font-size: 26px;
                    font-weight: 700;
                    margin-left: 15px;
                    text-align: right;
                }
            }
        }
    }
    .t-816-button-next-wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        .t-816-button-previous {
            align-items: center;
            color: rgb(62, 100, 173);
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-right: 15px;
            padding: 10px 25px;
            text-align: center;
            width: auto;
            .t-816-icon {
                align-items: center;
                display: flex;
                margin-right: 10px;
            }
        }
        .t-816-button-next {
            align-items: center;
            background: rgb(62, 100, 173);
            border-color: rgb(62, 100, 173);
            border-radius: 0px;
            border-style: solid;
            border-width: 1px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.3px;
            padding: 10px 25px;
            text-align: center;
            width: auto;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            .t-816-icon {
                align-items: center;
                display: flex;
                margin-left: 10px;
            }
        }
        .t-816-review-checkout-form {
            .t-816-text-stripe {
                color: #343434;
                font-size: 14px;
                margin: 10px 0 0 20px;
            }
        }
    }
}
