.template-block-782 {
    cursor: pointer;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    &.cover {
        .player-site {
            video {
                object-fit: cover;
            }
        }
    }
}
