.template-block-815 {
    display: block;
    letter-spacing: normal;
    line-height: normal;
    max-width: 100%;
    position: relative;
    white-space: pre-line;
    a {
        text-decoration: underline;
        transition: $mo-ease;
        &:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
}
