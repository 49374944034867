.portfolio-checkout-form {
    margin-bottom: 150px;
    .cf-text-stripe {
        color: #343434;
        font-size: 14px;
        margin: 10px 0 0 20px;
        &.is-mobile {
            margin-left: 0;
        }
    }
}
