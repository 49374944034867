.template-block-791 {
    max-width: 100%;
    outline: none;
    padding: 0;
    width: auto;
    z-index: 999;
    .bs-hamburger-box {
        border-radius: inherit;
        .bs-hamburger-inner {
            &:after {
                background: inherit;
                border-radius: inherit;
                width: inherit;
                height: inherit;
            }
            &:before {
                background: inherit;
                border-radius: inherit;
                width: inherit;
                height: inherit;
            }
        }
    }
    &.is-active {
        .bs-hamburger-inner {
            &:after {
                background: inherit;
                border-radius: inherit;
                width: inherit;
                height: inherit;
            }
            &:before {
                background: inherit;
                border-radius: inherit;
                width: inherit;
                height: inherit;
            }
        }
    }
}
