:root {
    --ion-font-family: "FuturaPT";
    --ion-toolbar-background: transparent;
    ion-input {
        --padding-end: 13px;
        --padding-start: 13px;
    }
    ion-item {
        --background-hover-opacity: 1;
        --background-hover: #383838;
        --background: #202020;
        --color-hover: #8ea1ff;
        --border-color: #383838;
    }
    ion-textarea {
        --padding-end: 13px;
        --padding-start: 13px;
    }
}
html {
    /* Hide scrollbar IE 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    /* Hide scrollbar Firefox */
    scrollbar-width: thin !important;
}
ion-app {
    user-select: auto !important;
}
.mo-hide-ion-content-scrollbar {
    // Keep order
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
    /* Hide scrollbar IE 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    /* Hide scrollbar Firefox */
    scrollbar-width: thin !important;
    &.is-windows {
        &::-webkit-scrollbar {
            background: #eae9e9;
            /* Hide scrollbar Safari and Chrome */
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a0a0a0;
        }
    }
}
.mo-hidden-horizontal-scrollbar {
    /* IE 10+ */
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    /* Hide scrollbar Firefox */
    scrollbar-width: none !important;
    &::-webkit-scrollbar {
        /* WebKit */
        height: 0;
        width: 0;
    }
}
.mo-hidden-vertical-scrollbar {
    /* Hide scrollbar IE 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    /* Hide scrollbar Firefox */
    scrollbar-width: thin !important;
    &.is-windows {
        &::-webkit-scrollbar {
            background: #eae9e9;
            /* Hide scrollbar Safari and Chrome */
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a0a0a0;
        }
    }
}
.alert-checkbox-group {
    border-color: $sitegray1 !important;
    [aria-checked="true"] {
        &.alert-tappable {
            background: $sitegray4;
        }
        .alert-checkbox-icon {
            background: var(--mo-color-mo-color) !important;
            border-color: var(--mo-color-mo-color) !important;
        }
        .alert-checkbox-inner {
            border-color: transparent;
        }
    }
}
.alert-radio-group {
    border-color: $sitegray1 !important;
    [aria-checked="true"] {
        &.alert-tappable {
            background: $sitegray4;
        }
        .alert-radio-inner {
            border-color: var(--mo-color-mo-color) !important;
        }
    }
}
.alert-button-group {
    .alert-button {
        border-color: $sitegray1 !important;
        &.ion-activated {
            color: var(--mo-color-mo-color);
        }
    }
}
.mo-body {
    font-family: $font1;
}
.mo-color {
    color: var(--mo-color-mo-color) !important;
}
a {
    color: inherit;
    text-decoration: none;
}
img {
    display: block;
}
td {
    img {
        max-width: none;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}
.ril-toolbar.ril__toolbar {
    background-color: transparent;
    .ril__toolbarItemChild {
        outline: none;
    }
}
.mo-clamp-web1 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mo-clamp-web5 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mo-loading {
    --background: #141418;
    --spinner-color: white;
}
.lg-19 {
    direction: rtl;
}
.userway_buttons_wrapper {
    bottom: 13px !important;
    top: auto !important;
}
