.template-block-799 {
    display: flex;
    max-width: 100%;
    .t-799-copyright {
        display: block;
    }
    .t-799-justtheweb {
        display: block;
    }
    .leaflet-container {
        background: #f4f4f2;
        height: 100%;
        width: 100%;
        a {
            color: initial;
            text-decoration: initial;
        }
        .hms-map-searchbox {
            border: 2px solid rgba(0, 0, 0, 0.2);
            .search-control {
                border-radius: 2px;
                .search-control-icon-button {
                    width: 30px;
                }
            }
        }
    }
    .t-799-geojson-map path {
        transition: fill 0.3s ease;
    }
    .t-799-svg-map {
        height: 100%;
        width: 100%;
        path {
            transition: fill 0.3s ease;
        }
    }
}
