.detail-modal-site {
    height: 100%;
    .dms-backdrop {
        background: rgba(0, 0, 0, 0.7);
        cursor: zoom-out;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .dms-navigation {
        &.is-mobile {
            align-items: center;
            background: black;
            color: white;
            display: flex;
            justify-content: space-between;
            padding: 10vh 20px 10px;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9999;
        }
        .dms-previous {
            align-items: center;
            color: white;
            display: flex;
            font-size: 30px;
            justify-content: center;
            left: 0;
            position: fixed;
            top: calc(50% - 22px);
            width: 80px;
            z-index: 10;
            &.is-mobile {
                color: white !important;
                font-size: 22px;
                font-weight: bold;
                position: initial;
            }
            .dms-icon {
                display: flex;
                z-index: 10;
            }
            .mo-new-icon-chevron-left-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dms-next {
            align-items: center;
            color: white;
            display: flex;
            font-size: 30px;
            justify-content: center;
            position: fixed;
            right: 0;
            top: calc(50% - 22px);
            width: 80px;
            z-index: 10;
            &.is-mobile {
                color: white !important;
                font-size: 22px;
                font-weight: bold;
                position: initial;
            }
            .dms-icon {
                display: flex;
                z-index: 10;
            }
            .mo-new-icon-chevron-right-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dms-close {
            color: white;
            cursor: pointer;
            font-size: 22px;
            position: fixed;
            right: 25px;
            top: 25px;
            z-index: 11;
            &.is-mobile {
                color: white !important;
                height: auto;
                position: initial;
            }
            .dms-close-icon {
                display: flex;
            }
        }
    }
    .dms-modal-wrap {
        height: calc(100% - 30px);
        margin: 15px 80px;
        position: relative;
        width: calc(100% - 160px);
        &.is-mobile {
            margin: 15px 20px;
            padding: 0;
            width: calc(100% - 40px);
        }
        .slug-page-content-wrap {
            .slug-page-content {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                min-height: calc(100vh - 30px);
                overflow: hidden;
                &.is-mobile {
                    min-height: calc(100vh - 53px);
                }
            }
        }
    }
}
.detail-modal-wrapper-site {
    min-height: 100%;
    --backdrop-opacity: 0 !important;
    &::part(content) {
        background: transparent;
        border-radius: 0;
        height: 100vh;
        position: relative;
        width: 100vw;
    }
}
