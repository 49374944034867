.image-input-819 {
    .ii-819-dropzone-input {
        align-items: center;
        display: flex;
        justify-content: center;
        .ii-819-input {
            align-items: center;
            border: 1px solid #e6e6e6;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 150px;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .icim-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
        .ii-819-preview-img {
            height: 200px;
            object-fit: contain;
            width: 100%;
        }
    }
}
