.text-input-site {
    margin-bottom: 30px;
    position: relative;
    .tis-form-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .tis-form-label-wrap {
            align-items: baseline;
            display: flex;
        }
    }
    .tis-input {
        border: none;
        min-height: 39px;
        outline: none;
        padding: 0 13px;
        width: 100%;
    }
    .tis-input,
    .tis-input-multiple {
        background: #1f1f24;
        border-radius: 8px;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    .tis-error {
        color: #dc3545;
        display: block;
        font-size: 12px;
        margin: 5px 0 10px;
        text-align: left;
        width: 100%;
    }
}
