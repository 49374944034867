.template-block-810 {
    display: flex;
    flex-direction: column;
    .t-810-sucess-message {
        color: white;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.5;
    }
    .t-810-label {
        color: white;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 0px 0px 5px;
        margin-top: 30px;
    }
    .t-810-currency {
        color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding-top: 5px;
    }
    .t-810-amount {
        background: transparent;
        border-color: rgb(47, 47, 47);
        border-style: solid;
        border-width: 1px;
        color: white;
        font-size: 16px;
        max-width: 100%;
        outline: none;
        padding: 10px;
    }
    .t-810-email {
        background: transparent;
        border-color: rgb(47, 47, 47);
        border-style: solid;
        border-width: 1px;
        color: white;
        font-size: 16px;
        max-width: 100%;
        outline: none;
        padding: 10px;
    }
    .t-810-error {
        color: red;
        font-size: 14px;
    }
    .t-810-monthly-wrap {
        cursor: pointer;
        color: white;
        margin-top: 30px;
        .t-810-monthly {
            margin-right: 10px;
        }
    }
    .t-810-button {
        background: white;
        border-color: rgb(0, 0, 0, 0);
        border-style: solid;
        border-width: 1px;
        color: black;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: normal;
        margin-top: 30px;
        max-width: 100%;
        padding: 10px 25px;
        text-align: center;
        transition: $mo-ease;
        width: auto;
        &:hover {
            background: black;
            border-color: white;
            color: white;
        }
        &.disabled {
            pointer-events: none;
        }
    }
    .t-810-secure {
        color: white;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding-top: 5px;
    }
    &.light {
        .t-810-amount,
        .t-810-currency,
        .t-810-email,
        .t-810-label,
        .t-810-monthly-wrap,
        .t-810-secure,
        .t-810-sucess-message {
            color: black;
        }
        .t-810-button {
            background: black;
            color: white;
            &:hover {
                background: white;
                border-color: black;
                color: black;
            }
        }
    }
}
