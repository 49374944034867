.template-block-798 {
    display: flex;
    max-width: 100%;
    .t-798-copyright {
        display: block;
    }
    .t-798-justtheweb {
        display: block;
    }
}
