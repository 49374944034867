@use "sass:math";
@if index($hamburger-types, spring) {
    /*
   * Spring
   */
    .hamburger--spring {
        .hamburger-inner {
            top: math.div($hamburger-layer-height, 2);
            transition: background-color 0s 0.13s linear;
            &::before {
                top: $hamburger-layer-height + $hamburger-layer-spacing;
                transition:
                    top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            &::after {
                top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
                transition:
                    top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }
        &.is-active {
            .hamburger-inner {
                transition-delay: 0.22s;
                background-color: transparent !important;
                &::before {
                    top: 0;
                    transition:
                        top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
                }
                &::after {
                    top: 0;
                    transition:
                        top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
                }
            }
        }
    }
}
