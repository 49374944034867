.tt-coming-soon {
    align-items: center;
    background-color: black;
    color: white;
    cursor: initial;
    display: flex;
    flex-direction: column;
    font-size: 36px;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    .tt-cs-wrap {
        text-align: center;
        .tt-cs-image {
            height: 130px;
            margin: 0 auto 30px;
            object-fit: contain;
        }
        .tt-cs-text {
            font-size: 13px;
            letter-spacing: 8.1px;
            margin-bottom: 30px;
            text-align: center;
            span {
                display: block;
            }
        }
        .tt-cs-name {
            display: block;
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 30px;
            text-transform: uppercase;
            word-break: break-word;
        }
        .tt-cs-description {
            display: block;
            font-size: 19px;
            letter-spacing: 0.3px;
            line-height: 1.5;
            margin: 0 auto 30px;
            max-width: 700px;
            white-space: pre-line;
        }
        .tt-cs-profile-wrap {
            img {
                height: 30px;
                margin: auto;
            }
            span {
                display: block;
                font-size: 12px;
                margin-bottom: 15px;
            }
        }
    }
}
