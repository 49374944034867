.location-input-816 {
    .li-816-chips {
        display: inline-block;
        padding: 10px 0 0;
        .li-816-image {
            border-radius: 50%;
            flex-shrink: 0;
            height: 20px;
            margin-right: 10px;
            object-fit: cover;
            width: 20px;
        }
        .li-816-cross {
            align-items: center;
            display: flex;
            margin-left: 7px;
        }
    }
    .list-ios,
    .list-md {
        background: transparent;
    }
    .li-816-label {
        text-overflow: initial;
        white-space: initial;
    }
}
