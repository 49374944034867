.input-816 {
    padding: 5px;
    width: 100%;
    &.is-mobile {
        padding-bottom: 10px;
    }
    .input-816-label {
        display: block;
        font-size: 12px;
        padding-bottom: 5px;
    }
    .input-816-error {
        color: #dc3545;
        display: block;
        font-size: 12px;
        margin: 5px 0 10px;
        text-align: left;
        width: 100%;
    }
}
