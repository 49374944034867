.course-helper-819 {
    align-items: center;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    &:last-child {
        border: none;
    }
    .ch-819-ch-info {
        flex: 1;
        .ch-819-name {
            font-size: 14px;
            font-weight: 500;
        }
        .ch-819-schedule {
            font-size: 14px;
            span {
                padding-right: 10px;
            }
        }
    }
    .t-819-variant-wrap {
        width: 100%;
    }
}
