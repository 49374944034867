.main-icon-block-site {
    background: transparent !important;
    border-radius: 5px;
    display: inline-block;
    height: auto;
    transition: $mo-ease;
    &:focus {
        outline: none;
    }
    &:hover {
        .main-icon {
            color: var(--mo-color-mo-color) !important;
            &.color {
                color: var(--mo-color-mo-color);
            }
        }
    }
    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    }
    .main-icon {
        align-items: center;
        color: white;
        display: flex;
        font-size: 17px;
        justify-content: center;
        padding: 10px;
        transition: $mo-ease;
    }
    &.light {
        .main-icon {
            color: black;
        }
        &:hover {
            .main-icon {
                color: var(--mo-color-mo-color) !important;
            }
        }
    }
}
