$mo-new-icon-font: "mo-new-icon";

@font-face {
    font-family: $mo-new-icon-font;
    src: url("./mo-new-icon.eot?cdc8d94f8f7d8065e331008292e4a566#iefix") format("embedded-opentype"),
url("./mo-new-icon.woff2?cdc8d94f8f7d8065e331008292e4a566") format("woff2"),
url("./mo-new-icon.woff?cdc8d94f8f7d8065e331008292e4a566") format("woff");
}

i[class^="mo-new-icon-"]:before, i[class*=" mo-new-icon-"]:before {
    font-family: mo-new-icon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$mo-new-icon-map: (
    "arrow-up-right-from-square-solid": "\f101",
    "arrows-alt-h-solid": "\f102",
    "arrows-alt-solid": "\f103",
    "arrows-alt-v-solid": "\f104",
    "bars-solid": "\f105",
    "bell-solid": "\f106",
    "caret-down-solid": "\f107",
    "caret-left-solid": "\f108",
    "caret-right-solid": "\f109",
    "caret-up-solid": "\f10a",
    "check-circle-regular": "\f10b",
    "check-circle-solid": "\f10c",
    "check-solid": "\f10d",
    "chevron-down-solid": "\f10e",
    "chevron-left-solid": "\f10f",
    "chevron-right-solid": "\f110",
    "chevron-up-solid": "\f111",
    "circle-solid": "\f112",
    "circle-xmark-regular": "\f113",
    "clipboard-regular": "\f114",
    "clock-regular": "\f115",
    "clock-solid": "\f116",
    "clone-regular": "\f117",
    "clone-solid": "\f118",
    "cloud-upload-alt-solid": "\f119",
    "cog-solid": "\f11a",
    "comment-dots-solid": "\f11b",
    "comment-slash-solid": "\f11c",
    "comment-solid": "\f11d",
    "compress-solid": "\f11e",
    "content": "\f11f",
    "copy-solid": "\f120",
    "desktop-solid": "\f121",
    "diagram-project-solid": "\f122",
    "ellipsis-h-solid": "\f123",
    "envelope-regular": "\f124",
    "expand-solid": "\f125",
    "eye-slash-solid": "\f126",
    "eye-solid": "\f127",
    "fast-backward-solid": "\f128",
    "fast-forward-solid": "\f129",
    "filter-circle-xmark-solid": "\f12a",
    "filter-solid": "\f12b",
    "gallery": "\f12c",
    "globe-solid": "\f12d",
    "home-solid": "\f12e",
    "layout-779-copy": "\f12f",
    "layout-779": "\f130",
    "layout-780-s": "\f131",
    "layout-780": "\f132",
    "layout-781": "\f133",
    "layout-782": "\f134",
    "layout-783": "\f135",
    "layout-784": "\f136",
    "layout-785": "\f137",
    "layout-786": "\f138",
    "layout-787": "\f139",
    "layout-789": "\f13a",
    "layout-790": "\f13b",
    "layout-791": "\f13c",
    "layout-792": "\f13d",
    "layout-793": "\f13e",
    "layout-794": "\f13f",
    "layout-795": "\f140",
    "layout-796": "\f141",
    "layout-797": "\f142",
    "layout-798": "\f143",
    "layout-799": "\f144",
    "link-solid": "\f145",
    "list-ul-solid": "\f146",
    "list": "\f147",
    "map-marked-alt-solid": "\f148",
    "map-marker-alt-solid": "\f149",
    "medium-types-1": "\f14a",
    "medium-types-12": "\f14b",
    "medium-types-13": "\f14c",
    "medium-types-15": "\f14d",
    "medium-types-16": "\f14e",
    "medium-types-18": "\f14f",
    "medium-types-2": "\f150",
    "medium-types-20": "\f151",
    "medium-types-22": "\f152",
    "medium-types-25": "\f153",
    "medium-types-26": "\f154",
    "medium-types-27": "\f155",
    "medium-types-28": "\f156",
    "medium-types-29": "\f157",
    "medium-types-3": "\f158",
    "medium-types-33": "\f159",
    "medium-types-4": "\f15a",
    "medium-types-5": "\f15b",
    "medium-types-6": "\f15c",
    "medium-types-7": "\f15d",
    "medium-types-8": "\f15e",
    "medium-types-9": "\f15f",
    "microphone-slash-solid": "\f160",
    "microphone-solid": "\f161",
    "minus-circle-solid": "\f162",
    "minus-solid": "\f163",
    "mobile-alt-solid": "\f164",
    "models-artwork": "\f165",
    "models-event": "\f166",
    "models-material": "\f167",
    "models-offer": "\f168",
    "models-profile": "\f169",
    "models-shop": "\f16a",
    "moon-solid": "\f16b",
    "paint-roller-solid": "\f16c",
    "paper-plane-solid": "\f16d",
    "paperclip-solid": "\f16e",
    "paste-solid": "\f16f",
    "pause-circle-regular": "\f170",
    "pause-solid": "\f171",
    "pencil-alt-solid": "\f172",
    "phone-solid": "\f173",
    "play-circle-regular": "\f174",
    "play-circle-solid": "\f175",
    "play-solid": "\f176",
    "plus-circle-solid": "\f177",
    "plus-solid": "\f178",
    "question-circle-regular": "\f179",
    "question-circle-solid": "\f17a",
    "quote-left-solid": "\f17b",
    "random-solid": "\f17c",
    "rss-solid": "\f17d",
    "search-solid": "\f17e",
    "share-alt-solid": "\f17f",
    "share-solid": "\f180",
    "sign-out-alt-solid": "\f181",
    "socialnetwork-1-round": "\f182",
    "socialnetwork-1-square": "\f183",
    "socialnetwork-1": "\f184",
    "socialnetwork-10": "\f185",
    "socialnetwork-13-square": "\f186",
    "socialnetwork-13": "\f187",
    "socialnetwork-14-square": "\f188",
    "socialnetwork-14": "\f189",
    "socialnetwork-16-square": "\f18a",
    "socialnetwork-16": "\f18b",
    "socialnetwork-17": "\f18c",
    "socialnetwork-18": "\f18d",
    "socialnetwork-19": "\f18e",
    "socialnetwork-2": "\f18f",
    "socialnetwork-21-square": "\f190",
    "socialnetwork-21": "\f191",
    "socialnetwork-22": "\f192",
    "socialnetwork-23-round": "\f193",
    "socialnetwork-23-square": "\f194",
    "socialnetwork-23": "\f195",
    "socialnetwork-25": "\f196",
    "socialnetwork-26": "\f197",
    "socialnetwork-3": "\f198",
    "socialnetwork-30-square": "\f199",
    "socialnetwork-30": "\f19a",
    "socialnetwork-33": "\f19b",
    "socialnetwork-37": "\f19c",
    "socialnetwork-38-square": "\f19d",
    "socialnetwork-38": "\f19e",
    "socialnetwork-39": "\f19f",
    "socialnetwork-41": "\f1a0",
    "socialnetwork-5": "\f1a1",
    "socialnetwork-6-square": "\f1a2",
    "socialnetwork-6-twitter": "\f1a3",
    "socialnetwork-8-square": "\f1a4",
    "socialnetwork-8": "\f1a5",
    "socialnetwork-9": "\f1a6",
    "sort-reverse": "\f1a7",
    "sort-solid": "\f1a8",
    "sort": "\f1a9",
    "staff-mag": "\f1aa",
    "staff-pick": "\f1ab",
    "star-regular": "\f1ac",
    "star-solid": "\f1ad",
    "step-backward-solid": "\f1ae",
    "step-forward-solid": "\f1af",
    "sunny-sharp": "\f1b0",
    "table-cells-large-solid": "\f1b1",
    "tablet-alt-solid": "\f1b2",
    "times-solid": "\f1b3",
    "trash-solid": "\f1b4",
    "undo-alt-solid": "\f1b5",
    "user-solid": "\f1b6",
    "users-slash-solid": "\f1b7",
    "users-solid": "\f1b8",
    "video-slash-solid": "\f1b9",
    "video-solid": "\f1ba",
    "volume-mute-solid": "\f1bb",
    "volume-up-solid": "\f1bc",
);

.mo-new-icon-arrow-up-right-from-square-solid:before {
    content: map-get($mo-new-icon-map, "arrow-up-right-from-square-solid");
}
.mo-new-icon-arrows-alt-h-solid:before {
    content: map-get($mo-new-icon-map, "arrows-alt-h-solid");
}
.mo-new-icon-arrows-alt-solid:before {
    content: map-get($mo-new-icon-map, "arrows-alt-solid");
}
.mo-new-icon-arrows-alt-v-solid:before {
    content: map-get($mo-new-icon-map, "arrows-alt-v-solid");
}
.mo-new-icon-bars-solid:before {
    content: map-get($mo-new-icon-map, "bars-solid");
}
.mo-new-icon-bell-solid:before {
    content: map-get($mo-new-icon-map, "bell-solid");
}
.mo-new-icon-caret-down-solid:before {
    content: map-get($mo-new-icon-map, "caret-down-solid");
}
.mo-new-icon-caret-left-solid:before {
    content: map-get($mo-new-icon-map, "caret-left-solid");
}
.mo-new-icon-caret-right-solid:before {
    content: map-get($mo-new-icon-map, "caret-right-solid");
}
.mo-new-icon-caret-up-solid:before {
    content: map-get($mo-new-icon-map, "caret-up-solid");
}
.mo-new-icon-check-circle-regular:before {
    content: map-get($mo-new-icon-map, "check-circle-regular");
}
.mo-new-icon-check-circle-solid:before {
    content: map-get($mo-new-icon-map, "check-circle-solid");
}
.mo-new-icon-check-solid:before {
    content: map-get($mo-new-icon-map, "check-solid");
}
.mo-new-icon-chevron-down-solid:before {
    content: map-get($mo-new-icon-map, "chevron-down-solid");
}
.mo-new-icon-chevron-left-solid:before {
    content: map-get($mo-new-icon-map, "chevron-left-solid");
}
.mo-new-icon-chevron-right-solid:before {
    content: map-get($mo-new-icon-map, "chevron-right-solid");
}
.mo-new-icon-chevron-up-solid:before {
    content: map-get($mo-new-icon-map, "chevron-up-solid");
}
.mo-new-icon-circle-solid:before {
    content: map-get($mo-new-icon-map, "circle-solid");
}
.mo-new-icon-circle-xmark-regular:before {
    content: map-get($mo-new-icon-map, "circle-xmark-regular");
}
.mo-new-icon-clipboard-regular:before {
    content: map-get($mo-new-icon-map, "clipboard-regular");
}
.mo-new-icon-clock-regular:before {
    content: map-get($mo-new-icon-map, "clock-regular");
}
.mo-new-icon-clock-solid:before {
    content: map-get($mo-new-icon-map, "clock-solid");
}
.mo-new-icon-clone-regular:before {
    content: map-get($mo-new-icon-map, "clone-regular");
}
.mo-new-icon-clone-solid:before {
    content: map-get($mo-new-icon-map, "clone-solid");
}
.mo-new-icon-cloud-upload-alt-solid:before {
    content: map-get($mo-new-icon-map, "cloud-upload-alt-solid");
}
.mo-new-icon-cog-solid:before {
    content: map-get($mo-new-icon-map, "cog-solid");
}
.mo-new-icon-comment-dots-solid:before {
    content: map-get($mo-new-icon-map, "comment-dots-solid");
}
.mo-new-icon-comment-slash-solid:before {
    content: map-get($mo-new-icon-map, "comment-slash-solid");
}
.mo-new-icon-comment-solid:before {
    content: map-get($mo-new-icon-map, "comment-solid");
}
.mo-new-icon-compress-solid:before {
    content: map-get($mo-new-icon-map, "compress-solid");
}
.mo-new-icon-content:before {
    content: map-get($mo-new-icon-map, "content");
}
.mo-new-icon-copy-solid:before {
    content: map-get($mo-new-icon-map, "copy-solid");
}
.mo-new-icon-desktop-solid:before {
    content: map-get($mo-new-icon-map, "desktop-solid");
}
.mo-new-icon-diagram-project-solid:before {
    content: map-get($mo-new-icon-map, "diagram-project-solid");
}
.mo-new-icon-ellipsis-h-solid:before {
    content: map-get($mo-new-icon-map, "ellipsis-h-solid");
}
.mo-new-icon-envelope-regular:before {
    content: map-get($mo-new-icon-map, "envelope-regular");
}
.mo-new-icon-expand-solid:before {
    content: map-get($mo-new-icon-map, "expand-solid");
}
.mo-new-icon-eye-slash-solid:before {
    content: map-get($mo-new-icon-map, "eye-slash-solid");
}
.mo-new-icon-eye-solid:before {
    content: map-get($mo-new-icon-map, "eye-solid");
}
.mo-new-icon-fast-backward-solid:before {
    content: map-get($mo-new-icon-map, "fast-backward-solid");
}
.mo-new-icon-fast-forward-solid:before {
    content: map-get($mo-new-icon-map, "fast-forward-solid");
}
.mo-new-icon-filter-circle-xmark-solid:before {
    content: map-get($mo-new-icon-map, "filter-circle-xmark-solid");
}
.mo-new-icon-filter-solid:before {
    content: map-get($mo-new-icon-map, "filter-solid");
}
.mo-new-icon-gallery:before {
    content: map-get($mo-new-icon-map, "gallery");
}
.mo-new-icon-globe-solid:before {
    content: map-get($mo-new-icon-map, "globe-solid");
}
.mo-new-icon-home-solid:before {
    content: map-get($mo-new-icon-map, "home-solid");
}
.mo-new-icon-layout-779-copy:before {
    content: map-get($mo-new-icon-map, "layout-779-copy");
}
.mo-new-icon-layout-779:before {
    content: map-get($mo-new-icon-map, "layout-779");
}
.mo-new-icon-layout-780-s:before {
    content: map-get($mo-new-icon-map, "layout-780-s");
}
.mo-new-icon-layout-780:before {
    content: map-get($mo-new-icon-map, "layout-780");
}
.mo-new-icon-layout-781:before {
    content: map-get($mo-new-icon-map, "layout-781");
}
.mo-new-icon-layout-782:before {
    content: map-get($mo-new-icon-map, "layout-782");
}
.mo-new-icon-layout-783:before {
    content: map-get($mo-new-icon-map, "layout-783");
}
.mo-new-icon-layout-784:before {
    content: map-get($mo-new-icon-map, "layout-784");
}
.mo-new-icon-layout-785:before {
    content: map-get($mo-new-icon-map, "layout-785");
}
.mo-new-icon-layout-786:before {
    content: map-get($mo-new-icon-map, "layout-786");
}
.mo-new-icon-layout-787:before {
    content: map-get($mo-new-icon-map, "layout-787");
}
.mo-new-icon-layout-789:before {
    content: map-get($mo-new-icon-map, "layout-789");
}
.mo-new-icon-layout-790:before {
    content: map-get($mo-new-icon-map, "layout-790");
}
.mo-new-icon-layout-791:before {
    content: map-get($mo-new-icon-map, "layout-791");
}
.mo-new-icon-layout-792:before {
    content: map-get($mo-new-icon-map, "layout-792");
}
.mo-new-icon-layout-793:before {
    content: map-get($mo-new-icon-map, "layout-793");
}
.mo-new-icon-layout-794:before {
    content: map-get($mo-new-icon-map, "layout-794");
}
.mo-new-icon-layout-795:before {
    content: map-get($mo-new-icon-map, "layout-795");
}
.mo-new-icon-layout-796:before {
    content: map-get($mo-new-icon-map, "layout-796");
}
.mo-new-icon-layout-797:before {
    content: map-get($mo-new-icon-map, "layout-797");
}
.mo-new-icon-layout-798:before {
    content: map-get($mo-new-icon-map, "layout-798");
}
.mo-new-icon-layout-799:before {
    content: map-get($mo-new-icon-map, "layout-799");
}
.mo-new-icon-link-solid:before {
    content: map-get($mo-new-icon-map, "link-solid");
}
.mo-new-icon-list-ul-solid:before {
    content: map-get($mo-new-icon-map, "list-ul-solid");
}
.mo-new-icon-list:before {
    content: map-get($mo-new-icon-map, "list");
}
.mo-new-icon-map-marked-alt-solid:before {
    content: map-get($mo-new-icon-map, "map-marked-alt-solid");
}
.mo-new-icon-map-marker-alt-solid:before {
    content: map-get($mo-new-icon-map, "map-marker-alt-solid");
}
.mo-new-icon-medium-types-1:before {
    content: map-get($mo-new-icon-map, "medium-types-1");
}
.mo-new-icon-medium-types-12:before {
    content: map-get($mo-new-icon-map, "medium-types-12");
}
.mo-new-icon-medium-types-13:before {
    content: map-get($mo-new-icon-map, "medium-types-13");
}
.mo-new-icon-medium-types-15:before {
    content: map-get($mo-new-icon-map, "medium-types-15");
}
.mo-new-icon-medium-types-16:before {
    content: map-get($mo-new-icon-map, "medium-types-16");
}
.mo-new-icon-medium-types-18:before {
    content: map-get($mo-new-icon-map, "medium-types-18");
}
.mo-new-icon-medium-types-2:before {
    content: map-get($mo-new-icon-map, "medium-types-2");
}
.mo-new-icon-medium-types-20:before {
    content: map-get($mo-new-icon-map, "medium-types-20");
}
.mo-new-icon-medium-types-22:before {
    content: map-get($mo-new-icon-map, "medium-types-22");
}
.mo-new-icon-medium-types-25:before {
    content: map-get($mo-new-icon-map, "medium-types-25");
}
.mo-new-icon-medium-types-26:before {
    content: map-get($mo-new-icon-map, "medium-types-26");
}
.mo-new-icon-medium-types-27:before {
    content: map-get($mo-new-icon-map, "medium-types-27");
}
.mo-new-icon-medium-types-28:before {
    content: map-get($mo-new-icon-map, "medium-types-28");
}
.mo-new-icon-medium-types-29:before {
    content: map-get($mo-new-icon-map, "medium-types-29");
}
.mo-new-icon-medium-types-3:before {
    content: map-get($mo-new-icon-map, "medium-types-3");
}
.mo-new-icon-medium-types-33:before {
    content: map-get($mo-new-icon-map, "medium-types-33");
}
.mo-new-icon-medium-types-4:before {
    content: map-get($mo-new-icon-map, "medium-types-4");
}
.mo-new-icon-medium-types-5:before {
    content: map-get($mo-new-icon-map, "medium-types-5");
}
.mo-new-icon-medium-types-6:before {
    content: map-get($mo-new-icon-map, "medium-types-6");
}
.mo-new-icon-medium-types-7:before {
    content: map-get($mo-new-icon-map, "medium-types-7");
}
.mo-new-icon-medium-types-8:before {
    content: map-get($mo-new-icon-map, "medium-types-8");
}
.mo-new-icon-medium-types-9:before {
    content: map-get($mo-new-icon-map, "medium-types-9");
}
.mo-new-icon-microphone-slash-solid:before {
    content: map-get($mo-new-icon-map, "microphone-slash-solid");
}
.mo-new-icon-microphone-solid:before {
    content: map-get($mo-new-icon-map, "microphone-solid");
}
.mo-new-icon-minus-circle-solid:before {
    content: map-get($mo-new-icon-map, "minus-circle-solid");
}
.mo-new-icon-minus-solid:before {
    content: map-get($mo-new-icon-map, "minus-solid");
}
.mo-new-icon-mobile-alt-solid:before {
    content: map-get($mo-new-icon-map, "mobile-alt-solid");
}
.mo-new-icon-models-artwork:before {
    content: map-get($mo-new-icon-map, "models-artwork");
}
.mo-new-icon-models-event:before {
    content: map-get($mo-new-icon-map, "models-event");
}
.mo-new-icon-models-material:before {
    content: map-get($mo-new-icon-map, "models-material");
}
.mo-new-icon-models-offer:before {
    content: map-get($mo-new-icon-map, "models-offer");
}
.mo-new-icon-models-profile:before {
    content: map-get($mo-new-icon-map, "models-profile");
}
.mo-new-icon-models-shop:before {
    content: map-get($mo-new-icon-map, "models-shop");
}
.mo-new-icon-moon-solid:before {
    content: map-get($mo-new-icon-map, "moon-solid");
}
.mo-new-icon-paint-roller-solid:before {
    content: map-get($mo-new-icon-map, "paint-roller-solid");
}
.mo-new-icon-paper-plane-solid:before {
    content: map-get($mo-new-icon-map, "paper-plane-solid");
}
.mo-new-icon-paperclip-solid:before {
    content: map-get($mo-new-icon-map, "paperclip-solid");
}
.mo-new-icon-paste-solid:before {
    content: map-get($mo-new-icon-map, "paste-solid");
}
.mo-new-icon-pause-circle-regular:before {
    content: map-get($mo-new-icon-map, "pause-circle-regular");
}
.mo-new-icon-pause-solid:before {
    content: map-get($mo-new-icon-map, "pause-solid");
}
.mo-new-icon-pencil-alt-solid:before {
    content: map-get($mo-new-icon-map, "pencil-alt-solid");
}
.mo-new-icon-phone-solid:before {
    content: map-get($mo-new-icon-map, "phone-solid");
}
.mo-new-icon-play-circle-regular:before {
    content: map-get($mo-new-icon-map, "play-circle-regular");
}
.mo-new-icon-play-circle-solid:before {
    content: map-get($mo-new-icon-map, "play-circle-solid");
}
.mo-new-icon-play-solid:before {
    content: map-get($mo-new-icon-map, "play-solid");
}
.mo-new-icon-plus-circle-solid:before {
    content: map-get($mo-new-icon-map, "plus-circle-solid");
}
.mo-new-icon-plus-solid:before {
    content: map-get($mo-new-icon-map, "plus-solid");
}
.mo-new-icon-question-circle-regular:before {
    content: map-get($mo-new-icon-map, "question-circle-regular");
}
.mo-new-icon-question-circle-solid:before {
    content: map-get($mo-new-icon-map, "question-circle-solid");
}
.mo-new-icon-quote-left-solid:before {
    content: map-get($mo-new-icon-map, "quote-left-solid");
}
.mo-new-icon-random-solid:before {
    content: map-get($mo-new-icon-map, "random-solid");
}
.mo-new-icon-rss-solid:before {
    content: map-get($mo-new-icon-map, "rss-solid");
}
.mo-new-icon-search-solid:before {
    content: map-get($mo-new-icon-map, "search-solid");
}
.mo-new-icon-share-alt-solid:before {
    content: map-get($mo-new-icon-map, "share-alt-solid");
}
.mo-new-icon-share-solid:before {
    content: map-get($mo-new-icon-map, "share-solid");
}
.mo-new-icon-sign-out-alt-solid:before {
    content: map-get($mo-new-icon-map, "sign-out-alt-solid");
}
.mo-new-icon-socialnetwork-1-round:before {
    content: map-get($mo-new-icon-map, "socialnetwork-1-round");
}
.mo-new-icon-socialnetwork-1-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-1-square");
}
.mo-new-icon-socialnetwork-1:before {
    content: map-get($mo-new-icon-map, "socialnetwork-1");
}
.mo-new-icon-socialnetwork-10:before {
    content: map-get($mo-new-icon-map, "socialnetwork-10");
}
.mo-new-icon-socialnetwork-13-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-13-square");
}
.mo-new-icon-socialnetwork-13:before {
    content: map-get($mo-new-icon-map, "socialnetwork-13");
}
.mo-new-icon-socialnetwork-14-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-14-square");
}
.mo-new-icon-socialnetwork-14:before {
    content: map-get($mo-new-icon-map, "socialnetwork-14");
}
.mo-new-icon-socialnetwork-16-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-16-square");
}
.mo-new-icon-socialnetwork-16:before {
    content: map-get($mo-new-icon-map, "socialnetwork-16");
}
.mo-new-icon-socialnetwork-17:before {
    content: map-get($mo-new-icon-map, "socialnetwork-17");
}
.mo-new-icon-socialnetwork-18:before {
    content: map-get($mo-new-icon-map, "socialnetwork-18");
}
.mo-new-icon-socialnetwork-19:before {
    content: map-get($mo-new-icon-map, "socialnetwork-19");
}
.mo-new-icon-socialnetwork-2:before {
    content: map-get($mo-new-icon-map, "socialnetwork-2");
}
.mo-new-icon-socialnetwork-21-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-21-square");
}
.mo-new-icon-socialnetwork-21:before {
    content: map-get($mo-new-icon-map, "socialnetwork-21");
}
.mo-new-icon-socialnetwork-22:before {
    content: map-get($mo-new-icon-map, "socialnetwork-22");
}
.mo-new-icon-socialnetwork-23-round:before {
    content: map-get($mo-new-icon-map, "socialnetwork-23-round");
}
.mo-new-icon-socialnetwork-23-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-23-square");
}
.mo-new-icon-socialnetwork-23:before {
    content: map-get($mo-new-icon-map, "socialnetwork-23");
}
.mo-new-icon-socialnetwork-25:before {
    content: map-get($mo-new-icon-map, "socialnetwork-25");
}
.mo-new-icon-socialnetwork-26:before {
    content: map-get($mo-new-icon-map, "socialnetwork-26");
}
.mo-new-icon-socialnetwork-3:before {
    content: map-get($mo-new-icon-map, "socialnetwork-3");
}
.mo-new-icon-socialnetwork-30-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-30-square");
}
.mo-new-icon-socialnetwork-30:before {
    content: map-get($mo-new-icon-map, "socialnetwork-30");
}
.mo-new-icon-socialnetwork-33:before {
    content: map-get($mo-new-icon-map, "socialnetwork-33");
}
.mo-new-icon-socialnetwork-37:before {
    content: map-get($mo-new-icon-map, "socialnetwork-37");
}
.mo-new-icon-socialnetwork-38-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-38-square");
}
.mo-new-icon-socialnetwork-38:before {
    content: map-get($mo-new-icon-map, "socialnetwork-38");
}
.mo-new-icon-socialnetwork-39:before {
    content: map-get($mo-new-icon-map, "socialnetwork-39");
}
.mo-new-icon-socialnetwork-41:before {
    content: map-get($mo-new-icon-map, "socialnetwork-41");
}
.mo-new-icon-socialnetwork-5:before {
    content: map-get($mo-new-icon-map, "socialnetwork-5");
}
.mo-new-icon-socialnetwork-6-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-6-square");
}
.mo-new-icon-socialnetwork-6-twitter:before {
    content: map-get($mo-new-icon-map, "socialnetwork-6-twitter");
}
.mo-new-icon-socialnetwork-8-square:before {
    content: map-get($mo-new-icon-map, "socialnetwork-8-square");
}
.mo-new-icon-socialnetwork-8:before {
    content: map-get($mo-new-icon-map, "socialnetwork-8");
}
.mo-new-icon-socialnetwork-9:before {
    content: map-get($mo-new-icon-map, "socialnetwork-9");
}
.mo-new-icon-sort-reverse:before {
    content: map-get($mo-new-icon-map, "sort-reverse");
}
.mo-new-icon-sort-solid:before {
    content: map-get($mo-new-icon-map, "sort-solid");
}
.mo-new-icon-sort:before {
    content: map-get($mo-new-icon-map, "sort");
}
.mo-new-icon-staff-mag:before {
    content: map-get($mo-new-icon-map, "staff-mag");
}
.mo-new-icon-staff-pick:before {
    content: map-get($mo-new-icon-map, "staff-pick");
}
.mo-new-icon-star-regular:before {
    content: map-get($mo-new-icon-map, "star-regular");
}
.mo-new-icon-star-solid:before {
    content: map-get($mo-new-icon-map, "star-solid");
}
.mo-new-icon-step-backward-solid:before {
    content: map-get($mo-new-icon-map, "step-backward-solid");
}
.mo-new-icon-step-forward-solid:before {
    content: map-get($mo-new-icon-map, "step-forward-solid");
}
.mo-new-icon-sunny-sharp:before {
    content: map-get($mo-new-icon-map, "sunny-sharp");
}
.mo-new-icon-table-cells-large-solid:before {
    content: map-get($mo-new-icon-map, "table-cells-large-solid");
}
.mo-new-icon-tablet-alt-solid:before {
    content: map-get($mo-new-icon-map, "tablet-alt-solid");
}
.mo-new-icon-times-solid:before {
    content: map-get($mo-new-icon-map, "times-solid");
}
.mo-new-icon-trash-solid:before {
    content: map-get($mo-new-icon-map, "trash-solid");
}
.mo-new-icon-undo-alt-solid:before {
    content: map-get($mo-new-icon-map, "undo-alt-solid");
}
.mo-new-icon-user-solid:before {
    content: map-get($mo-new-icon-map, "user-solid");
}
.mo-new-icon-users-slash-solid:before {
    content: map-get($mo-new-icon-map, "users-slash-solid");
}
.mo-new-icon-users-solid:before {
    content: map-get($mo-new-icon-map, "users-solid");
}
.mo-new-icon-video-slash-solid:before {
    content: map-get($mo-new-icon-map, "video-slash-solid");
}
.mo-new-icon-video-solid:before {
    content: map-get($mo-new-icon-map, "video-solid");
}
.mo-new-icon-volume-mute-solid:before {
    content: map-get($mo-new-icon-map, "volume-mute-solid");
}
.mo-new-icon-volume-up-solid:before {
    content: map-get($mo-new-icon-map, "volume-up-solid");
}
