.template-block-808 {
    align-items: center;
    display: flex;
    width: auto;
    .t-808-input {
        background: rgba(0, 0, 0, 0.07);
        border-radius: 7px;
        border: none;
        margin-right: 15px;
        outline: none;
        padding: 0;
        transition: $mo-ease;
        width: 0;
        &:focus {
            outline: none;
        }
        &.active {
            opacity: 1;
            padding: 6px 13px;
            pointer-events: initial;
            width: auto;
        }
    }
    .t-808-icon {
        align-items: center;
        color: black;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        transition: $mo-ease;
        &:hover {
            opacity: 0.7;
        }
    }
}
