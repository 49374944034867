.checkout-site {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100vh;
    letter-spacing: 0.3px;
    transition: $mo-ease;
    width: 100vw;
    &.is-mobile {
        height: auto;
        width: auto;
    }
    .cs-content {
        background: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        color: black;
        height: 100vh;
        max-width: 100%;
        overflow: auto;
        padding: 20px 0 100px;
        position: relative;
        width: 400px;
        &.is-mobile {
            display: none;
        }
        .cs-cart-list {
            padding: 0 10px;
            .cs-cart-total {
                font-size: 14px;
                font-weight: bold;
                padding-bottom: 30px;
                text-transform: uppercase;
            }
            .cs-lines {
                display: flex;
                margin-bottom: 20px;
                .cs-image-wrap {
                    height: 120px;
                    width: 120px;
                    .cs-image {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }
                .cs-info {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 200px;
                    .cs-info-wrap {
                        flex: 1;
                        .cs-name {
                            font-weight: bold;
                        }
                    }
                    .cs-variant,
                    .cs-price,
                    .cs-line-total {
                        color: #989898;
                    }
                    span {
                        display: block;
                        margin-bottom: 10px;
                    }
                    .cs-line-total {
                        margin-bottom: 0;
                    }
                }
                .cs-actions {
                    cursor: pointer;
                    text-align: center;
                    width: 50px;
                }
            }
        }
        .cs-footer {
            align-items: center;
            background: #303133;
            bottom: 0;
            color: white;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: bold;
            height: 100px;
            justify-content: center;
            max-width: 100%;
            padding: 10px;
            position: fixed;
            width: 400px;
            &.is-mobile {
                width: 100%;
            }
        }
    }
}
