.variant-helper-819 {
    align-items: center;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px 0;
    &:last-child {
        border: none;
    }
    .vh-819-name {
        flex: 1;
        font-size: 14px;
        padding-right: 20px;
        .vh-819-name-decription {
            font-size: 11px;
        }
    }
    .vh-819-variant-price-wrap {
        align-items: center;
        display: flex;
        .vh-819-variant-price {
            font-size: 16px;
        }
        .vh-819-input-wrap {
            padding-left: 20px;
            input {
                background: transparent;
                border: 1px solid #e6e6e6;
                max-width: 100%;
                outline: none;
                text-align: center;
                width: 70px;
            }
        }
        .vh-819-name-full {
            display: block;
            font-size: 11px;
            text-align: center;
            width: 70px;
        }
    }
}
