.template-block-817 {
    display: block;
    width: 100%;
    // max-width: 100%;
    // visibility: hidden;
    // &.visible {
    //     visibility: initial;
    // }
    // .t-817-arrow {
    //     align-items: center;
    //     color: white;
    //     display: flex;
    //     font-size: 30px;
    //     justify-content: center;
    // }
    // .slick-prev {
    //     height: auto;
    //     left: 25px;
    //     width: auto;
    //     z-index: 1;
    // }
    // .slick-next {
    //     height: auto;
    //     right: 25px;
    //     width: auto;
    //     z-index: 1;
    // }
    // .slick-next:before,
    // .slick-prev:before {
    //     content: "";
    // }
    // .slick-track {
    //     align-items: center;
    //     display: flex;
    // }
}
