.cart-popup-block {
    background: rgba(0, 0, 0, 0.7);
    display: none; // need because of bug on position (unknown reason)
    height: 100vh;
    letter-spacing: 0.3px;
    margin-right: -100vw;
    position: fixed;
    right: 0;
    top: 0;
    transition: $mo-ease;
    width: 100vw;
    z-index: 99999;
    .cpb-backdrop {
        cursor: zoom-out;
        width: calc(100vw - 400px);
        &.is-mobile {
            display: none;
        }
    }
    .cpb-close {
        color: black;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }
    .cpb-content {
        background: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        color: black;
        height: 100vh;
        max-width: 100%;
        overflow: auto;
        padding: 20px 0 100px;
        position: relative;
        width: 400px;
        &.is-mobile {
            width: 100%;
        }
        .cpb-cart-list {
            padding: 0 10px;
            .cpb-cart-total {
                font-size: 14px;
                font-weight: bold;
                padding-bottom: 30px;
                text-transform: uppercase;
            }
            .cpb-lines {
                display: flex;
                margin-bottom: 20px;
                .cpb-image-wrap {
                    height: 120px;
                    width: 120px;
                    .cpb-image {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }
                .cpb-info {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 200px;
                    .cpb-info-wrap {
                        flex: 1;
                        .cpb-name {
                            font-weight: bold;
                        }
                    }
                    .cpb-variant,
                    .cpb-price,
                    .cpb-line-total {
                        color: #989898;
                    }
                    span {
                        display: block;
                        margin-bottom: 10px;
                    }
                    .cpb-line-total {
                        margin-bottom: 0;
                    }
                }
                .cpb-actions {
                    cursor: pointer;
                    text-align: center;
                    width: 50px;
                }
            }
        }
        .cpb-footer {
            align-items: center;
            background: #303133;
            bottom: 0;
            color: white;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: bold;
            height: 100px;
            justify-content: center;
            max-width: 100%;
            padding: 10px;
            position: fixed;
            width: 400px;
            &.is-mobile {
                width: 100%;
            }
            .cpb-subtotal {
                padding-bottom: 10px;
            }
            .cpb-checkout {
                background-color: #303133;
                border-color: #303133;
                border-style: solid;
                border-width: 1px;
                color: white;
                cursor: pointer;
                display: block;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.3px;
                padding: 10px;
                text-align: center;
                text-transform: uppercase;
                transition: $mo-ease;
                &:hover {
                    background-color: white;
                    color: #303133;
                }
            }
        }
    }
    &.open {
        display: flex; // need because of bug on position (unknown reason)
        margin-right: 0;
    }
}
