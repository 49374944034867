.template-block-790 {
    max-width: 100%;
    visibility: hidden;
    &.variable-width {
        .slick-slide>div>img {
            width: auto !important;
        }
    }
    &.visible {
        visibility: initial;
    }
    &.slick-track-baseline {
        .slick-track {
            align-items: baseline;
        }
    }
    &.slick-track-flex-end {
        .slick-track {
            align-items: flex-end;
        }
    }
    &.slick-track-flex-start {
        .slick-track {
            align-items: flex-start;
        }
    }
    &.slick-track-stretch {
        .slick-track {
            align-items: stretch;
        }
    }
    .t-790-arrow {
        align-items: center;
        color: white;
        display: flex;
        font-size: 30px;
        justify-content: center;
    }
    .slick-prev {
        height: auto;
        left: 25px;
        width: auto;
        z-index: 1;
    }
    .slick-next {
        height: auto;
        right: 25px;
        width: auto;
        z-index: 1;
    }
    .slick-next:before,
    .slick-prev:before {
        content: "";
    }
    .slick-track {
        align-items: center;
        display: flex;
    }
}
