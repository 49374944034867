.hub-map-site {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    .hms-map {
        flex: 1;
        .leaflet-container {
            background: #f4f4f2;
            height: 100%;
            width: 100%;
            a {
                color: initial;
                text-decoration: initial;
            }
            .hms-map-searchbox {
                border: 2px solid rgba(0, 0, 0, 0.2);
                .search-control {
                    border-radius: 2px;
                    .search-control-icon-button {
                        width: 30px;
                    }
                }
            }
        }
    }
    .hms-map-items {
        width: 300px;
        .hms-map-item-detail {
            padding: 0 5px;
        }
    }
    &.is-mobile,
    &.is-tablet {
        padding-top: 50px;
    }
}
