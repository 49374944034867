.template-block-796 {
    max-width: 100%;
    .t-796-checkbox-wrap {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
    }
}
.t-796-snackbar {
    .MuiSnackbarContent-root {
        background: blue;
        color: white;
    }
}
