.private-side-menu-tab-site {
    border-bottom: 2px solid #e8e8e8;
    .psmts-space-wrap {
        .psmts-space-wrap-name {
            border-bottom: 1px solid #e8e8e8;
            padding: 10px 0;
            text-transform: uppercase;
        }
    }
    .psmts-tab-item-wrap {
        display: flex;
        .psmts-expand {
            align-items: center;
            color: black;
            cursor: pointer;
            display: flex;
            margin-right: 10px;
            transition: $mo-ease;
            &.hidden {
                visibility: hidden;
            }
            &.open {
                transform: rotate(90deg);
            }
        }
        .psmts-tab-item {
            padding-left: 5px;
            flex: 1;
        }
    }
    .psmts-tab-item,
    .psmts-tab-item-children {
        border-bottom: 1px solid #e8e8e8;
        display: block;
        padding: 7px 10px 7px 0;
        &.active {
            color: #ed1550;
            font-weight: bold;
            &.p-480 {
                color: #f9551f;
            }
        }
    }
    .psmts-children-wrap {
        border-left: 1px solid #e8e8e8;
        display: none;
        padding-left: 15px;
        &.open {
            display: block;
        }
    }
}
