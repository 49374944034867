.button-site {
    display: flex;
    .bs-wrap {
        display: flex;
        text-align: center;
        &.disabled {
            pointer-events: none;
        }
        .bs-button {
            cursor: pointer;
            display: inline;
            transition: $mo-ease;
            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
        }
    }
}
