.hub-map-list {
    width: 300px;
    .hml-filter-wrap {
        .hub-filter-site {
            padding: 10px 5px 5px;
            .hub-filter-input-site {
                width: 100%;
            }
        }
    }
}
