.template-block-793 {
    display: block;
    max-width: 100%;
    position: relative;
    .t-793-dropdown-content {
        display: none;
        position: absolute;
        width: auto;
        z-index: 1;
        &.layout-type-pr.is-mobile,
        &.layout-type-pr.is-tablet {
            display: block;
            position: relative;
        }
    }
    &:hover {
        .t-793-dropdown-content {
            display: block;
        }
    }
}
