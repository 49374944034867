.image-helper-site {
    height: 100%;
    position: relative;
    width: 100%;
    .mo-ihwvh {
        visibility: hidden;
    }
    .mo-ihwvv {
        visibility: visible;
    }
    .mo-image-loader {
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}
.visibility-hidden {
    visibility: hidden;
}
