.hub-page-site {
    background: white;
    .hps-content {
        border-top: 1px solid #e8e8e8;
        display: flex;
        position: relative;
        .hps-left {
            border-right: 1px solid #e8e8e8;
            padding: 0 15px 15px;
            position: relative;
            width: 240px;
            z-index: 999;
            &.is-mobile,
            &.is-tablet {
                background: white;
                left: 0;
                margin-left: -240px;
                position: absolute;
                top: 50px;
                &.open {
                    margin-left: 0;
                }
            }
            .hps-header-left {
                background: white;
                position: sticky;
                top: 0;
                .hps-thumb-wrap {
                    border-bottom: 1px solid #e8e8e8;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-around;
                    height: 50px;
                    .hps-size-icon {
                        .main-icon {
                            font-size: 19px;
                        }
                        &:hover,
                        &.active {
                            .main-icon {
                                color: #ed1550 !important;
                            }
                            &.p-480 {
                                .main-icon {
                                    color: #f9551f !important;
                                }
                            }
                        }
                    }
                    &.hidden {
                        visibility: hidden;
                    }
                }
            }
        }
        .hps-right {
            flex: 1;
            padding-bottom: 10px;
            position: relative;
            .hps-header {
                background: white;
                border-bottom: 1px solid #e8e8e8;
                display: flex;
                height: 50px;
                overflow: hidden;
                position: sticky;
                top: 0;
                z-index: 1000;
                &.map {
                    width: 300px;
                    .hps-search-wrap {
                        align-items: flex-start;
                        flex-direction: column;
                        .hps-search-in-list {
                            margin: 0;
                            padding: 5px 0 5px 15px;
                        }
                    }
                    &.is-mobile,
                    &.is-tablet {
                        align-items: center;
                        position: fixed;
                        top: 55px;
                        width: 100vw;
                    }
                }
                .hps-search-wrap {
                    align-items: center;
                    display: flex;
                    padding: 0 5px;
                    .hps-search-wrap-block {
                        align-items: center;
                        display: flex;
                        .hps-searchbar {
                            height: 50px;
                            padding: 0 10px;
                            width: 350px;
                            &.map {
                                width: 250px;
                            }
                            &.is-mobile,
                            &.is-tablet {
                                display: contents;
                                width: auto;
                            }
                            .searchbar-search-icon {
                                display: none;
                                height: 0;
                                width: 0;
                            }
                            .searchbar-input {
                                padding-inline-start: 13px;
                            }
                        }
                    }
                    .hps-search-in-list {
                        color: black;
                        cursor: pointer;
                        font-size: 14px;
                        margin-left: 40px;
                        text-decoration: underline;
                        transition: $mo-ease;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                    .hps-icon {
                        &:hover {
                            .main-icon {
                                color: #ed1550 !important;
                            }
                        }
                    }
                }
            }
            .hps-list {
                display: flex;
                flex-wrap: wrap;
                &.list {
                    padding: 20px 5%;
                }
                &.square {
                    padding: 20px;
                }
            }
            .hps-no-more {
                color: black;
                text-align: center;
                width: 100%;
            }
            &.map {
                flex: initial;
                padding-bottom: 0;
                width: calc(100vw - 240px);
                &.is-mobile,
                &.is-tablet {
                    width: 100vw;
                }
            }
        }
    }
    &.dark {
        background: black;
        .hps-content {
            border-top: 1px solid #262626;
            .hps-left {
                border-right: 1px solid #262626;
                &.is-mobile,
                &.is-tablet {
                    background: black;
                }
                .hps-header-left {
                    background: black;
                    .hps-thumb-wrap {
                        border-bottom: 1px solid #262626;
                        .hps-size-icon {
                            .main-icon {
                                color: white;
                            }
                            &:hover,
                            &.active {
                                .main-icon {
                                    color: #ed1550 !important;
                                }
                            }
                        }
                    }
                }
                .hub-side-menu-tab-site {
                    .hsmts-tab-item-wrap {
                        .hsmts-expand {
                            color: white;
                        }
                        .hsmts-tab-item {
                            flex: 1;
                            text-transform: uppercase;
                        }
                    }
                    .hsmts-tab-item,
                    .hsmts-tab-item-children {
                        border-bottom: 1px solid #262626;
                        color: white;
                        &.active {
                            color: #ed1550;
                            &.p-480 {
                                color: #f9551f;
                            }
                        }
                    }
                    .hsmts-children-wrap {
                        border-left: 1px solid #262626;
                    }
                }
            }
            .hps-right {
                .hps-header {
                    background: black;
                    border-bottom: 1px solid #262626;
                    .hps-search-wrap {
                        .hps-searchbar {
                            --background: #212121;
                            --color: white;
                        }
                        .main-icon {
                            color: white;
                        }
                    }
                    .hps-search-in-list {
                        color: white;
                    }
                }
                .hps-no-more {
                    color: white;
                }
            }
        }
    }
}
