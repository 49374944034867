.activity-helper-819 {
    border-bottom: 3px solid #e6e6e6;
    padding: 10px 0;
    &:last-child {
        border: none;
    }
    .ah-819-activity-wrap {
        align-items: center;
        cursor: pointer;
        display: flex;
        .ah-819-name {
            flex: 1;
            font-size: 16px;
            font-weight: 600;
        }
        .ah-819-action-wrap {
            align-items: center;
            display: flex;
            .ah-819-price-wrap {
                span {
                    font-size: 13px;
                    padding-right: 10px;
                }
            }
            .ah-819-action-icon {
                transition: transform 0.3s ease;
                &.active {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .ah-819-course {
        padding-top: 10px;
    }
}
