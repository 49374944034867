.slug-page-site {
    min-height: 100%;
    .slug-page-scroll-button {
        align-items: center;
        background: rgba(243, 243, 243, 0.5);
        bottom: 20px;
        display: flex;
        height: 40px;
        justify-content: center;
        position: fixed;
        right: 20px;
        transition: $mo-ease;
        width: 40px;
        .main-icon-block-site {
            .main-icon {
                color: #354bce;
            }
            &:hover {
                .main-icon {
                    color: #354bce !important;
                    &.color {
                        color: #354bce;
                    }
                }
            }
        }
    }
}
.slug-page-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    transition: opacity 0.3s ease;
    &.opacity {
        opacity: 0;
    }
    &.no-opacity {
        opacity: 1;
    }
    .slug-page-content-list {
        flex: 1;
    }
    [id^="content-section-"] {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
[id^="content-new-section-"] {
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
        margin-bottom: -1px;
    }
}
[id^="content-new-child-"] {
    position: relative;
    width: 100%;
}
