.template-block-779 {
    display: block;
    letter-spacing: normal;
    line-height: normal;
    max-width: 100%;
    position: relative;
    white-space: pre-line;
    a {
        text-decoration: underline;
        transition: $mo-ease;
        &:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
    &.clamp {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.text-gradient {
        -webkit-background-clip: text !important;
        background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
    }
    &.transition {
        transition: $mo-ease;
    }
}
.template-block-779-rich {
    display: block;
    letter-spacing: normal;
    line-height: normal;
    max-width: 100%;
    position: relative;
    &.clamp {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.default-margin {
        a,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
            margin-block-end: 1em;
            margin-block-start: 1em;
        }
    }
    &.text-gradient {
        -webkit-background-clip: text !important;
        background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
    }
    &.transition {
        transition: $mo-ease;
    }
    a {
        text-decoration: underline;
        transition: $mo-ease;
        &:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
    blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        padding: 10px 0 10px 20px;
    }
    img {
        display: initial;
    }
    // &.link-344,
    // &.link-364 {
    // 	a {
    // 		color: #ed1550;
    // 	}
    // }
}
