.consent-block-site {
    .MuiSnackbarContent-root {
        background: blue;
        color: white;
    }
    &.p-393 {
        .MuiSnackbarContent-root {
            background: #f90101;
        }
    }
}
