.portfolio-checkout {
    background: white;
    letter-spacing: 0.3px;
    overflow-y: auto;
    padding: 20px;
    width: calc(100vw - 400px);
    &.is-mobile {
        padding-top: 50px;
        width: 100%;
    }
    .pc-wrap {
        .slis-error,
        .tis-error {
            position: absolute;
        }
        .pc-form-error {
            color: #dc3545;
            display: block;
            font-size: 12px;
            margin: 5px 0;
            padding-left: 20px;
            text-align: left;
            width: 100%;
        }
        .pc-title {
            color: black;
            font-size: 29px;
            font-weight: bold;
            margin-bottom: 20px;
            padding: 0 20px;
            &.is-mobile {
                padding: 0;
            }
        }
        .pc-checkbox-wrap {
            align-items: center;
            display: flex;
            padding: 0 20px 20px;
            .pc-checkbox {
                color: #303133;
                font-size: 14px;
                padding-left: 10px;
            }
            &.is-mobile {
                padding-left: 0;
            }
        }
        .pc-contact-info {
            .text-input-site {
                display: inline-block;
                padding: 0 20px;
                width: 50%;
                .tis-input,
                .tis-input-multiple {
                    background: transparent;
                    border: 1px solid #cccccc;
                    color: black;
                }
                &.is-mobile {
                    padding: 0;
                    width: 100%;
                }
            }
        }
        .pc-shipping-info {
            .text-input-site {
                display: inline-block;
                padding: 0 20px;
                width: 50%;
                .tis-input,
                .tis-input-multiple {
                    background: transparent;
                    border: 1px solid #cccccc;
                    color: black;
                }
                &.is-mobile {
                    padding: 0;
                    width: 100%;
                }
            }
            .select-list-input-site {
                display: inline-block;
                padding: 0 20px;
                width: 50%;
                &.is-mobile {
                    padding: 0;
                    width: 100%;
                }
            }
            .pc-shipping-type {
                display: flex;
                flex-direction: row;
                padding: 0 20px 20px;
                &.is-mobile {
                    flex-direction: column;
                    padding: 0 0 20px;
                    width: 100%;
                }
                label {
                    padding: 0 15px 0 5px;
                }
            }
            .pc-mondial-relay-widget {
                padding: 0 20px 20px;
                height: 550px;
                &.is-mobile {
                    padding: 0 0 20px;
                    height: 450px;
                    width: 100%;
                }
            }
        }
        .pc-shipping-cost {
            .pc-other-costs {
                padding: 0 20px 20px;
                .pc-other-costs-item {
                    border: 1px solid #cccccc;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    &.active {
                        background: #cccccc;
                    }
                    &.is-mobile {
                        padding: 0 0 20px;
                    }
                }
            }
            .pc-shipping-cost-text {
                color: black;
                font-size: 16px;
                margin-bottom: 20px;
                padding: 0 20px;
                &.is-mobile {
                    padding: 0;
                }
            }
        }
        .pc-billing-info {
            .portfolio-checkbox-input {
                padding-left: 20px !important;
                margin-bottom: 20px !important;
                &.is-mobile {
                    padding: 0 !important;
                }
            }
            .text-input-site {
                display: inline-block;
                width: 50%;
                padding: 0 20px;
                .tis-input,
                .tis-input-multiple {
                    background: transparent;
                    border: 1px solid #cccccc;
                    color: black;
                }
                &.is-mobile {
                    padding: 0;
                    width: 100%;
                }
            }
            .select-list-input-site {
                display: inline-block;
                width: 50%;
                padding: 0 20px;
                &.is-mobile {
                    padding: 0;
                    width: 100%;
                }
            }
        }
        .pc-validation {
            .pc-validation-button {
                background-color: #303133;
                border-color: #303133;
                border-style: solid;
                border-width: 1px;
                color: white;
                cursor: pointer;
                display: block;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.3px;
                margin: 0 20px;
                padding: 20px;
                text-align: center;
                text-transform: uppercase;
                transition: $mo-ease;
                width: calc(50% - 40px);
                &:hover {
                    background-color: white;
                    color: #303133;
                }
                &.is-mobile {
                    margin: 0;
                    width: 100%;
                }
                &.error {
                    pointer-events: none;
                }
            }
        }
    }
}
