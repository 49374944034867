.mo-player-track-list-site {
    .mptls-header {
        background: #181818;
        border-bottom: 1px solid #383838;
    }
    .mptls-footer {
        background: #181818;
        border-top: 1px solid #383838;
        padding: 10px;
    }
    .mptls-title {
        color: white;
    }
    .mptls-trackname {
        color: white;
        font-size: 16px;
        padding-left: 15px;
    }
}
