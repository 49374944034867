.phone-number-input-819 {
    .PhoneInputInput {
        background: transparent;
        border: 1px solid #e6e6e6;
        outline: none;
        padding: 7px 13px;
        width: 100%;
    }
    .PhoneInputCountry {
        border: 0;
    }
}
