.google-login-block-site {
    margin: 0 auto 25px;
    padding-top: 20px;
    width: 300px;
    .glbs-button {
        border-radius: 34px !important;
        border: none !important;
        color: black !important;
        font-family: $font1 !important;
        font-size: 19px !important;
        font-weight: 600 !important;
        justify-content: center;
        outline: none;
        width: 100%;
        &:focus {
            outline: none;
        }
    }
    .glbs-error {
        color: red;
        display: block;
        margin: 5px 0 10px;
        text-align: center;
        width: 100%;
    }
}
