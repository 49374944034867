@if index($hamburger-types, arrowturn) {
    /*
   * Arrow Turn
   */
    .hamburger--arrowturn.is-active {
        .hamburger-inner {
            transform: rotate(-180deg);
            &::before {
                transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            }
            &::after {
                transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            }
        }
    }
}
