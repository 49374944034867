.date-time-input-819 {
    .dti-819-input {
        background: transparent;
        border: 1px solid #e6e6e6;
        outline: none;
        padding: 7px 13px;
        width: 100%;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-popper {
        z-index: 3;
    }
}
