.not-found-page-site {
    background: black;
    .nfps-content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100%;
        .nfps-wrap {
            align-items: center;
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.8) 0,
                    rgba(0, 0, 0, 0.5) 40%,
                    rgba(0, 0, 0, 0.5) 70%,
                    rgba(0, 0, 0, 0.8));
            color: white;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 20px;
            width: 100%;
            .nfps-text1 {
                font-size: 100px;
                font-weight: bold;
            }
            .nfps-text2 {
                font-size: 24px;
                padding-bottom: 30px;
                text-align: center;
            }
            .nfps-button {
                display: block;
                font-weight: bold;
                text-align: center;
                &.bg {
                    background: white;
                    border-radius: 10px;
                    border: 1px solid white;
                    color: black;
                    margin-bottom: 20px;
                    padding: 10px;
                    transition: $mo-ease;
                    &:hover {
                        background: transparent;
                        color: white;
                    }
                }
            }
        }
    }
    &.in-modal {
        .nfps-content {
            height: calc(100vh - 30px);
            &.is-mobile {
                height: calc(100vh - 53px);
            }
        }
    }
}
