.template-block-806 {
    padding-top: 20px;
    max-width: 100%;
    width: 100%;
    .t-806-google {
        width: 100%;
    }
    .t-806-separation-block {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        .t-806-separation-line {
            width: 40%;
            hr {
                border-style: solid;
                border-top-width: 1px;
                display: block;
                height: 1px;
                margin: 1em 0;
                padding: 0;
            }
        }
    }
    .t-806-input-wrap {
        padding-bottom: 25px;
        .t-806-label-wrap {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
        .t-806-label {
            padding-bottom: 5px;
        }
        .t-806-input {
            border-radius: 10px;
            border: none;
            outline: none;
            padding: 7px 13px;
            width: 100%;
            &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }
        }
        .t-806-error {
            color: red;
            display: block;
            margin: 5px 0 10px;
            width: 100%;
        }
    }
    .t-806-verification-wrap {
        padding-bottom: 25px;
        .t-806-label {
            text-align: center;
        }
        .t-806-verification-input {
            padding-top: 10px;
            display: flex;
            justify-content: center;
        }
    }
    .t-806-button-wrap {
        .t-806-button {
            border-radius: 34px;
            border: 1px solid transparent;
            font-size: 16px;
            font-weight: bold;
            padding: 15px 20px;
            transition: $mo-ease;
            width: 100%;
            &:disabled {
                opacity: 0.7;
                pointer-events: none;
            }
            &:hover {
                background: transparent;
            }
        }
        .t-806-error {
            color: red;
            display: block;
            margin: 5px 0 10px;
            text-align: center;
            width: 100%;
        }
    }
    .t-806-link-wrap {
        padding-top: 20px;
        .t-806-link {
            cursor: pointer;
            display: block;
            font-size: 14px;
            text-align: center;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
