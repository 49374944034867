.checkbox-input-816 {
    .ci-816-wrap {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        .input-816-label {
            padding: 0 0 0 10px;
        }
    }
}
