.private-slug-page-site {
    .psps-content {
        border-top: 1px solid #e8e8e8;
        display: flex;
        position: relative;
        .psps-left {
            border-right: 1px solid #e8e8e8;
            padding: 0 10px 10px;
            position: relative;
            width: 240px;
            z-index: 999;
            &.is-mobile,
            &.is-tablet {
                background: white;
                left: 0;
                margin-left: -240px;
                position: absolute;
                top: 50px;
                &.open {
                    margin-left: 0;
                }
            }
        }
        .psps-right {
            flex: 1;
            position: relative;
            .psps-header {
                background: white;
                border-bottom: 1px solid #e8e8e8;
                display: flex;
                height: 50px;
                overflow: hidden;
                position: sticky;
                top: 0;
                z-index: 1000;
            }
        }
    }
}
.private-slug-page-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: opacity 0.3s ease;
    &.opacity {
        opacity: 0;
    }
    &.no-opacity {
        opacity: 1;
    }
    .private-slug-page-content-list {
        flex: 1;
    }
    [id^="content-section-"] {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
