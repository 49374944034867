.template-block-809 {
    height: 100%;
    width: 100%;
    .t-809-image {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        &.hidden {
            display: none;
        }
    }
    .t-809-name-wrap {
        display: flex;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
    video {
        object-fit: cover;
    }
}
