@font-face {
    font-family: "FuturaPT";
    font-style: normal;
    font-weight: normal;
    src:
        url("FuturaPT-Book.woff2") format("woff2"),
        url("FuturaPT-Book.woff") format("woff"),
        url("FuturaPT-Book.ttf") format("truetype");
}
@font-face {
    font-family: "FuturaPT";
    font-style: normal;
    font-weight: 600;
    src:
        url("FuturaPT-DemiBold.woff2") format("woff2"),
        url("FuturaPT-DemiBold.woff") format("woff"),
        url("FuturaPT-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "FuturaPT";
    font-style: normal;
    font-weight: bold;
    src:
        url("FuturaPT-Bold.woff2") format("woff2"),
        url("FuturaPT-Bold.woff") format("woff"),
        url("FuturaPT-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "FuturaPT";
    font-style: normal;
    font-weight: 800;
    src:
        url("FuturaPT-ExtraBold.woff2") format("woff2"),
        url("FuturaPT-ExtraBold.woff") format("woff"),
        url("FuturaPT-ExtraBold.ttf") format("truetype");
}
