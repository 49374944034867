.template-block-763 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        line-height: 1.5;
    }
    display: flex;
    letter-spacing: 0.3px;
    &.is-mobile {
        flex-direction: column-reverse;
    }
    .t-763-confirm-message {
        padding: 50px;
        text-align: center;
        font-size: 23px;
        font-weight: bold;
        white-space: pre-line;
        width: 100%;
    }
    .t-763-selection-wrap {
        width: 50%;
        padding: 50px;
        &.is-mobile {
            padding: 0;
            width: 100%;
        }
        .t-763-selection {
            margin-bottom: 20px;
            .t-763-image {
                width: 100px;
                &.is-mobile {
                    width: 50px;
                }
            }
            .t-763-selection-name {
                color: black;
                font-size: 16px;
                font-weight: bold;
                padding-left: 30px;
            }
        }
    }
    .t-763-submit-wrap {
        background: #fbfbfb;
        padding: 50px;
        width: 50%;
        &.is-mobile {
            padding: 20px;
            width: 100%;
        }
        .t-763-title {
            font-family: "Oswald";
            font-size: 40px;
            font-weight: bold;
            padding-bottom: 25px;
            text-transform: uppercase;
        }
        .t-763-description {
            border-bottom: solid 1px #dddddd;
            font-size: 16px;
            margin-bottom: 20px;
            padding-bottom: 20px;
            white-space: pre-line;
        }
        .t-763-success {
            font-size: 23px;
            font-weight: bold;
            white-space: pre-line;
        }
        .text-input-site {
            .tis-input,
            .tis-input-multiple {
                background: white;
                color: black;
                border: 1px solid #dddddd;
            }
        }
        .t-763-error-message {
            color: #dc3545;
            padding-top: 10px;
            font-size: 12px;
        }
        .t-763-button-wrap {
            .t-763-button {
                background: black;
                border: 1px solid black;
                color: white;
                font-family: "Oswald";
                padding: 10px 60px;
                text-transform: uppercase;
                &:hover {
                    background: white;
                    color: black;
                }
            }
        }
    }
}
