.staff-helper-site {
    align-items: center;
    bottom: 20px;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 20px;
    transition: $mo-ease;
    .main-icon-block-site {
        background: rgba(142, 161, 255, 0.9) !important;
        p {
            color: white;
            padding: 10px;
        }
        .main-icon {
            color: white;
        }
        &.ml {
            margin-left: 10px;
        }
        &:hover {
            .main-icon {
                color: white !important;
                &.color {
                    color: white;
                }
            }
        }
    }
}
