.form-success-modal-site {
    background: rgba(24, 24, 24, 0.1);
    .mo-dialog-wrapper {
        &.fix-width {
            width: 600px;
        }
        .mo-dialog-header {
            position: relative;
            .mo-dialog-header-close-wrap {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 10px;
                .mo-dialog-header-close {
                    background: transparent;
                    color: white;
                    .main-icon {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}
