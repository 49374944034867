.location-input-819 {
    .li-819-chips {
        display: inline-block;
        padding: 10px 0 0;
        .li-819-image {
            border-radius: 50%;
            flex-shrink: 0;
            height: 20px;
            margin-right: 10px;
            object-fit: cover;
            width: 20px;
        }
        .li-819-cross {
            align-items: center;
            display: flex;
            margin-left: 7px;
        }
    }
    .list-ios,
    .list-md {
        background: transparent;
    }
    .li-819-label {
        text-overflow: initial;
        white-space: initial;
    }
}
