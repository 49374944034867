.checkbox-input-819 {
    .ci-819-wrap {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        .input-819-label {
            padding: 0 0 0 10px;
        }
    }
}
