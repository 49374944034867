.template-block-797 {
    background: transparent;
    border: none;
    max-width: 100%;
    outline: none;
    padding: 0;
    width: 100%;
    &.checkbox {
        margin: 0 10px 5px 0;
        width: auto;
    }
    &.checkboxmultiple {
        display: flex;
        flex-wrap: wrap;
        .t-797-checkboxmultiple-label {
            margin-left: 10px;
        }
    }
    &.placeholder-black {
        &::placeholder {
            color: black;
            /* Firefox */
            opacity: 1;
        }
        /* Internet Explorer 10-11 */
        &:-ms-input-placeholder {
            color: black;
        }
        /* Microsoft Edge */
        &::-ms-input-placeholder {
            color: black;
        }
    }
    &.placeholder-white {
        &::placeholder {
            color: white;
            /* Firefox */
            opacity: 1;
        }
        /* Internet Explorer 10-11 */
        &:-ms-input-placeholder {
            color: white;
        }
        /* Microsoft Edge */
        &::-ms-input-placeholder {
            color: white;
        }
    }
    &[type='number'] {
        -moz-appearance: textfield; // Remove the spinner in Firefox
        appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:hover,
        &:focus {
            -moz-appearance: textfield;
        }
    }
    .t-797-dropzone-input {
        align-items: center;
        display: flex;
        justify-content: center;
        .t-797-input {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 150px;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .icim-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
        .t-797-preview-img {
            height: 200px;
            object-fit: contain;
            width: 100%;
        }
    }
    .PhoneInputInput {
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        width: 100%;
    }
    .PhoneInputCountry {
        border: 0;
        select {
            background: white;
            color: black;
        }
    }
}
.t-797-helper {
    display: block;
    font-size: 11px;
}
.t-797-error {
    color: red;
    font-size: 14px;
}
.react-datepicker-wrapper {
    width: 100%;
}
