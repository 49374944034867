.template-block-802 {
    max-width: 100%;
    .t-802-variants {
        background: transparent;
        border: none;
        cursor: pointer;
        max-width: 100%;
        outline: none;
    }
    .t-802-actions {
        align-items: center;
        display: flex;
        input {
            background: transparent;
            border: none;
            max-width: 100%;
            outline: none;
        }
    }
    .t-802-button {
        cursor: pointer;
        transition: $mo-ease;
        &.extra-button {
            display: block;
        }
    }
}
