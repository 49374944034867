.template-block-792 {
    background: transparent;
    max-width: 100%;
    padding: 0;
    position: relative;
    width: auto;
    .t-792-badge {
        align-items: center;
        background-color: red;
        border-radius: 50%;
        color: white;
        display: flex;
        font-size: 12px;
        height: 20px;
        justify-content: center;
        position: absolute;
        right: -10px;
        text-align: center;
        top: -10px;
        width: 20px;
        z-index: 5;
        &.is-mobile {
            right: 0;
        }
    }
    .t-792-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        transition: $mo-ease;
    }
}
