.template-block-783 {
    display: inline-block;
    letter-spacing: normal;
    line-height: normal;
    max-width: 100%;
    transition: $mo-ease;
    &.disabled {
        pointer-events: none;
    }
}
.template-block-783-banque-postal-modal {
    &::part(content) {
        height: 200px;
    }
    .t-783-content {
        display: flex;
        flex-direction: column;
        height: 200px;
        justify-content: center;
        padding: 20px;
        width: 100%;
        .t-783-text {
            padding-bottom: 20px;
        }
        .t-783-form {
            width: 100%;
        }
    }
}
