.login-page-site {
    .lps-content {
        background: var(--mo-color-bg1);
        display: flex;
        @media (max-width: 576px) {
            height: 100%;
        }
        .lps-left {
            padding: 20px;
            max-width: 500px;
            margin: auto;
            width: 50%;
            @media (max-width: 576px) {
                width: 100%;
            }
            .lps-title {
                font-size: 56px;
                font-weight: bold;
                line-height: 1;
                margin-bottom: 30px;
                text-align: center;
                text-transform: uppercase;
            }
            .lps-input-wrap {
                padding-bottom: 25px;
                .lps-label-wrap {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
                .lps-label {
                    padding-bottom: 5px;
                }
                .lps-input {
                    border-radius: 10px;
                    border: 1px solid;
                    outline: none;
                    padding: 7px 13px;
                    width: 100%;
                    &:disabled {
                        cursor: not-allowed;
                        opacity: 0.7;
                    }
                }
                .lps-error {
                    color: red;
                    display: block;
                    margin: 5px 0 10px;
                    width: 100%;
                }
            }
            .lps-button-wrap {
                .lps-button {
                    border-radius: 34px;
                    border: 1px solid transparent;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 15px 20px;
                    transition: $mo-ease;
                    width: 100%;
                    &:disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
                    &:hover {
                        background: transparent;
                    }
                }
                .lps-error {
                    color: red;
                    display: block;
                    margin: 5px 0 10px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
        .lps-right {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100vh;
            width: 50%;
            @media (max-width: 576px) {
                display: none;
            }
        }
    }
}
