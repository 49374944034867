.template-block-805 {
    display: flex;
    max-width: 100%;
    width: 100%;
    .t-805-image,
    .t-805-video {
        margin: auto;
        max-width: 100%;
        object-fit: contain;
    }
}
