.checkout-response-site {
    .t-crs-confirmation {
        padding: 40px 20px;
        .t-crs-text {
            font-size: 29px;
            padding-bottom: 20px;
            text-align: center;
            white-space: pre-line;
        }
        .t-crs-products {
            max-width: 400px;
            margin: auto;
            padding: 10px 0;
            .t-crs-lines {
                display: flex;
                margin-bottom: 20px;
                .t-crs-image-wrap {
                    height: 120px;
                    width: 120px;
                    .t-crs-image {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }
                .t-crs-info {
                    font-size: 14px;
                    padding-left: 10px;
                    width: 200px;
                    .t-crs-name {
                        font-weight: bold;
                    }
                    .t-crs-variant,
                    .t-crs-price,
                    .t-crs-line-total {
                        color: #989898;
                    }
                    span {
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }
            .t-crs-shipping {
                text-align: center;
            }
            .t-crs-subtotal {
                font-size: 29px;
                font-weight: bold;
                margin-top: 10px;
                text-align: center;
            }
        }
    }
    .t-crs-cancel {
        padding: 40px 20px;
        text-align: center;
        .t-crs-text {
            font-size: 29px;
            color: #303133;
            padding-bottom: 20px;
        }
        .t-crs-button {
            background-color: #303133;
            border-color: #303133;
            border-style: solid;
            border-width: 1px;
            color: white;
            cursor: pointer;
            display: block;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.3px;
            margin: auto;
            padding: 20px;
            text-align: center;
            text-transform: uppercase;
            transition: $mo-ease;
            width: 250px;
            &:hover {
                background-color: white;
                color: #303133;
            }
        }
    }
}
